<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>5eme Economique</ion-card-title>
        </ion-card-header>
      </ion-card>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Elèves
          </ion-label>
        </ion-list-header>
        <ion-item :router-link="'/classroom/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Elmore James</h2>
          </ion-label>
        </ion-item>
        <ion-item :router-link="'/classroom/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Big Bill Broonzy</h2>
          </ion-label>
        </ion-item>
        <ion-item :router-link="'/classroom/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Furry Lewis</h2>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>


<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonItem } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Payment',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton, IonItem
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

</style>