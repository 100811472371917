<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>C.E.S.P. Leuze</ion-card-title>
          <ion-card-subtitle>Ajout d'un traiteur</ion-card-subtitle>
        </ion-card-header>
      </ion-card>
      <form onSubmit={this.processForm}>
        <ion-list lines="full" class="ion-no-margin ion-no-padding">
          <ion-item>
            <ion-label position="stacked">Nom <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Email <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="email" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Numéro de téléphone <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="tel" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Rue <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Numéro <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Boite <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Code postal <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Ville <ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="text" value=""></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="stacked">Nombre de jour avant validation<ion-text color="danger">*</ion-text></ion-label>
            <ion-input required type="number" value="7"></ion-input>
          </ion-item>
        </ion-list>
        <br>
        <section style="padding: 0 10px">
          <ion-button expand="block">Ajouter le traiteur</ion-button>
        </section>
      </form>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "AddCaterer",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton,
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

</style>