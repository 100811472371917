
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: "AddCaterer",
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton,
  },
  setup () {
    return {
      copyOutline
    }
  },
});
