<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Elmore James</ion-card-title>
        </ion-card-header>
      </ion-card>
      <section style="padding: 0 10px">
        <ion-button :router-link="'/classroom/student/receive_payment'" router-direction="forward" expand="block">Recevoir un paiement en liquide</ion-button>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButton } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ClassroomStudent',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton, IonButton
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

</style>