<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Lightnin' Hopkins</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <h1><em>Solde de l'élève:</em> <strong style="color: red">-13€</strong></h1>
        </ion-card-content>
      </ion-card>
      <ion-card>
        <ion-card-content>
          Coordonées de paiement pour l'élève: <br><br>
          <strong>Ecole:</strong><br>
          <h2 style="font-size: 1.0em; margin-bottom: 2px;">St Luc, tournai <ion-button size="small" color="light" style="margin-top: -4px; font-size: 0.7em"><ion-icon :icon="copyOutline" ></ion-icon>&nbsp;Copier</ion-button></h2>
          <strong>Numéro de compte:</strong><br>
          <h2 style="font-size: 1.0em">BE01 2345 6789 0123 <ion-button size="small" color="light" style="margin-top: -4px; font-size: 0.7em"><ion-icon :icon="copyOutline" ></ion-icon>&nbsp;Copier</ion-button></h2>
          <strong>Communication:</strong><br>
          <h2 style="font-size: 1.0em">123-456789-12 <ion-button size="small" color="light" style="margin-top: -4px; font-size: 0.7em"><ion-icon :icon="copyOutline" ></ion-icon>&nbsp;Copier</ion-button></h2>
        </ion-card-content>
      </ion-card>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Mes virements
          </ion-label>
        </ion-list-header>
        <ion-item>
          <ion-label>
            <h2>60€</h2>
            <h3>03 Juillet 2021</h3>
            <p>Depuis le compte BE01 1234 5678 9012</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>49€</h2>
            <h3>19 mars 2021</h3>
            <p>En liquide, à Mme Kimya Dawson</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>60€</h2>
            <h3>03 Juillet 2021</h3>
            <p>Depuis le compte BE01 1234 5678 9012</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>49€</h2>
            <h3>19 mars 2021</h3>
            <p>En liquide, à Mme Kimya Dawson</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>60€</h2>
            <h3>03 Juillet 2021</h3>
            <p>Depuis le compte BE01 1234 5678 9012</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h2>49€</h2>
            <h3>19 mars 2021</h3>
            <p>En liquide, à Mme Kimya Dawson</p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>

</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Payment',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton,
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

</style>