
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButton } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'School',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton, IonButton
  },
  setup () {
    return {
      copyOutline
    }
  },
});
