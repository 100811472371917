
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonListHeader, IonItem, IonLabel, IonAvatar, IonBackButton, IonButtons } from '@ionic/vue';
import { peopleCircleOutline, warningOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonList, IonListHeader, IonItem, IonLabel, IonAvatar, IonBackButton, IonButtons
  },
  setup () {
    return {
      peopleCircleOutline, warningOutline
    }
  },
});
