<template>
  <ion-page>
    <form>
      <ion-card>
        <ion-card-header color="primary">
          <h3>School Manager</h3>
        </ion-card-header>
        <ion-item>
          <h3>Se connecter</h3>
        </ion-item>
        <ion-item>
          <ion-label position="floating">Login</ion-label>
          <ion-input v-model="form.login" id="username" required></ion-input>
        </ion-item>

        <ion-item>
          <ion-label position="floating">Mot de passe</ion-label>
          <ion-input type="password" v-model="form.password" id="password" required></ion-input>
        </ion-item>

        <ion-item>
          <ion-button :router-link="'/home'" router-direction="forward">
            Se connecter
            <ion-icon slot="end" :icon="logIn"></ion-icon>
          </ion-button>
        </ion-item>
      </ion-card>
    </form>
  </ion-page>
</template>

<script lang="ts">
import { IonIcon, IonPage, IonCard, IonItem, IonCardHeader, IonLabel, IonButton, IonInput } from '@ionic/vue';
import { logIn } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default  defineComponent({
  name: "Login.vue",
  components: {
    IonPage,
    IonIcon,
    IonCard,
    IonItem,
    IonCardHeader,
    IonLabel,
    IonButton,
    IonInput
  },
  setup () {
    return {
      logIn
    }
  },
  data () {
    return {
      form: {
        login: null,
        password: null
      }
    }
  }
});
</script>

<style scoped>

</style>