<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>1ere D Latin/Math, Institut Saint-Luc</ion-card-subtitle>
          <ion-card-title>Lightnin' Hopkins</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Solde de l'élève: <strong style="color: green">+26€</strong><br>
          Solde des frais à venire: <strong style="color: orange">-48€</strong>
        </ion-card-content>
      </ion-card>
      <section style="padding: 0 10px">
        <ion-button :router-link="'/meals'" router-direction="forward" expand="block">Réserver les repas</ion-button>
        <ion-button :router-link="'/payment'" router-direction="forward" expand="block">Effectuer un paiement</ion-button>
        <ion-button :router-link="'/student/edit'" router-direction="forward" expand="block">Modifier les informations</ion-button>
      </section>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Tuteurs légeaux
          </ion-label>
        </ion-list-header>
        <ion-item :router-link="'/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Little Walter</h2>
            <h3>lttl@walter.net</h3>
          </ion-label>
        </ion-item>
        <ion-item :router-link="'/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Janis Joplin</h2>
            <h3>janis@gmail.com</h3>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonListHeader, IonItem, IonLabel, IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Student',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton, IonButtons, IonButton
  }
});
</script>

<style scoped>

</style>