<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Lightnin' Hopkins</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          Réservez/décomandez ici les repas pour l'élève.
        </ion-card-content>
      </ion-card>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button>
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
          <ion-button>
            <ion-icon :icon="chevronForwardOutline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <ion-title>Semaine 37, 13-17/09</ion-title>
      </ion-toolbar>
      <ion-list>
        <ion-item-sliding>
          <ion-item>
            <ion-label>Lundi 17/09</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Mardi 18/09</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Mercredi 19/09</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Jeudi 20/09</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Vendredi 21/09</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
        </ion-item-sliding>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonList, IonListHeader, IonItem, IonLabel, IonAvatar } from '@ionic/vue';
import { chevronForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Meals',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton, IonButtons
  },
  setup () {
    return {
      chevronForwardOutline,
      chevronBackOutline
    }
  },
});
</script>

<style scoped>

</style>