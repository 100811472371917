import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Student from '../views/Student.vue'
import Meals from '../views/Meals.vue'
import Payment from '../views/Payment.vue'
import EditStudent from "@/views/Student/EditStudent.vue";
import School from "@/views/School.vue";
import Classroom from "@/views/Classroom.vue";
import ClassroomStudent from '../views/Classroom/Student.vue'
import Attendance from "@/views/School/Attendance.vue";
import ReceivePayment from "@/views/Student/ReceivePayment.vue";
import AddCaterer from "@/views/School/AddCaterer.vue";
import AddUser from "@/views/School/AddUser.vue";
import AddStudent from "@/views/School/AddStudent.vue";
import AddClassroom from "@/views/School/AddClassroom.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/student',
    name: 'Student',
    component: Student
  },
  {
    path: '/student/edit',
    name: 'EditStudent',
    component: EditStudent
  },
  {
    path: '/meals',
    name: 'Meals',
    component: Meals
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/school',
    name: 'School',
    component: School
  },
  {
    path: '/school/add_caterer',
    name: 'AddCaterer',
    component: AddCaterer
  },
  {
    path: '/school/add_user',
    name: 'AddUser',
    component: AddUser
  },
  {
    path: '/school/add_student',
    name: 'AddStudent',
    component: AddStudent
  },
  {
    path: '/school/add_classroom',
    name: 'AddClassroom',
    component: AddClassroom
  },
  {
    path: '/classroom',
    name: 'Classroom',
    component: Classroom
  },
  {
    path: '/classroom/student',
    name: 'ClassroomStudent',
    component: ClassroomStudent
  },
  {
    path: '/classroom/student/receive_payment',
    name: 'ReceivePayment',
    component: ReceivePayment
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: Attendance
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
