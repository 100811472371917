
import { IonIcon, IonPage, IonCard, IonItem, IonCardHeader, IonLabel, IonButton, IonInput } from '@ionic/vue';
import { logIn } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default  defineComponent({
  name: "Login.vue",
  components: {
    IonPage,
    IonIcon,
    IonCard,
    IonItem,
    IonCardHeader,
    IonLabel,
    IonButton,
    IonInput
  },
  setup () {
    return {
      logIn
    }
  },
  data () {
    return {
      form: {
        login: null,
        password: null
      }
    }
  }
});
