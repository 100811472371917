<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-buttons slot="start">
        <ion-back-button defaultHref="/"></ion-back-button>
      </ion-buttons>
      <ion-toolbar>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list>
        <ion-list-header>
          <ion-label>
            Mes enfants
          </ion-label>
        </ion-list-header>
        <ion-item :router-link="'/student'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Bob Brozman</h2>
            <h3>3eme D Latin/Math</h3>
            <p>Collège Notre Dame de Tournai</p>
          </ion-label>
        </ion-item>
        <ion-item :router-link="'/student'">
          <ion-badge slot="end" color="danger">
            <ion-icon :icon="warningOutline"></ion-icon>
          </ion-badge>
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h2>Howlin' Wolf</h2>
            <h3>5eme C Biotechnique</h3>
            <p>C.E.S.P, Leuze</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Mes classes
          </ion-label>
        </ion-list-header>
        <ion-item :router-link="'/classroom'">
          <ion-avatar slot="start">
            <ion-icon size="large" :icon="peopleCircleOutline" ></ion-icon>
          </ion-avatar>
          <ion-label>
            <h2>5eme Economique</h2>
            <p>Collège Notre Dame de Tournai</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Mes écoles
          </ion-label>
        </ion-list-header>
        <ion-item :router-link="'/school'">
          <ion-avatar slot="start">
            <img alt="avatar" src="/assets/school.png" />
          </ion-avatar>
          <ion-label>
            <h2>Collège Notre Dame de Tournai</h2>
            <p></p>
          </ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonList, IonListHeader, IonItem, IonLabel, IonAvatar, IonBackButton, IonButtons } from '@ionic/vue';
import { peopleCircleOutline, warningOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar, IonList, IonListHeader, IonItem, IonLabel, IonAvatar, IonBackButton, IonButtons
  },
  setup () {
    return {
      peopleCircleOutline, warningOutline
    }
  },
});
</script>

<style scoped>
</style>