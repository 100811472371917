<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button defaultHref="/"></ion-back-button>
        </ion-buttons>
        <ion-title>Nina Simone</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>Présences du jour</ion-card-title>
        </ion-card-header>
      </ion-card>
      <ion-list>
        <ion-list-header>
          <ion-label>
            Elèves inscrits
          </ion-label>
        </ion-list-header>
        <ion-item-sliding>
          <ion-item>
            <ion-label>Elmore James</ion-label>
            <ion-checkbox slot="end" ></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Fury Lewis</ion-label>
            <ion-checkbox slot="end" ></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Bob Brozman</ion-label>
            <ion-checkbox slot="end" checked></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Jerry Lee Lewis</ion-label>
            <ion-checkbox slot="end" ></ion-checkbox>
          </ion-item>
          <ion-item>
            <ion-label>Jane Fonda</ion-label>
            <ion-checkbox slot="end" ></ion-checkbox>
          </ion-item>
        </ion-item-sliding>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonItem } from '@ionic/vue';
import { copyOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Attendance',
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle, IonToolbar, IonBackButton, IonItem
  },
  setup () {
    return {
      copyOutline
    }
  },
});
</script>

<style scoped>

</style>