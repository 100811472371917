
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonBackButton, IonButtons, IonButton, IonList, IonListHeader, IonItem, IonLabel, IonAvatar } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Student',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton, IonButtons, IonButton
  }
});
